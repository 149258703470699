import React from 'react'
import { useState } from 'react'
import { config, useSpring } from 'react-spring'
import { graphql } from 'gatsby'
import axios from "axios";
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import Img from 'gatsby-image'
import 'bootstrap/dist/css/bootstrap.min.css'

const Contact = ({ data }) => {

    const pageAnimation = useSpring({
      config: config.slow,
      from: { opacity: 0 },
      to: { opacity: 1 },
    })

    const [serverState, setServerState] = useState({
      submitting: false,
      status: null
    });

    const handleServerResponse = (ok, msg, form) => {
      setServerState({
        submitting: false,
        status: { ok, msg }
      });
      if (ok) {
        form.reset();
      }
    };

    const handleOnSubmit = e => {
      console.log ("I am here")
      e.preventDefault();
      const form = e.target;
      setServerState({ submitting: true });
      axios({
        method: "post",
        url: "https://getform.io/f/3ded90c0-d7a0-45f6-a387-1fc2137a0fad",
        data: new FormData(form)
      })
      .then(r => {
        handleServerResponse(true, "Thank you, your message has been received!", form) ;
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form);
      });
    };
  
    return (  
      <Layout>
        <SEO title="Contact Us | Charlie Trotter Photography" desc="Hi. I'm Charlie Trotter You can visit my website or my portfolio of projects." />
        <AnimatedBox style={pageAnimation} py={[6, 6, 6, 8]} px={[6, 6, 8, 6, 8, 13]}>
          <div className="container">
          <h1>Contact Me</h1>
          <br />
          <div className="row">
            <div className="col-lg-7">
              <form onSubmit={ handleOnSubmit }>
                <div className="form-group form-group-lg">
                <label className="col-12" for="inCompany">
                    Company:
                    <input id="inCompany" className="form-control input-lg" type="text" name="company" placeholder="Ex: ABC Company" />
                </label>
                </div>
                <div className="form-group form-group-lg">
                <label className="col-12" for="inEmail">
                    Email (Required):
                    <input id="inEmail" className="form-control input-lg" type="email" name="email" placeholder="Ex: john.doe@mail.com" required />
                </label>
                </div>
                <div className="form-group form-group-lg">
                <label className="col-12" for="inName">
                    Name:
                    <input id="inName" className="form-control input-lg" type="text" name="name" placeholder="Ex: John Doe" />
                </label>
                </div>
                <div className="form-group form-group-lg">
                <label className="col-12" for="inPhone">
                    Phone:
                    <input id="inPhone" className="form-control input-lg" type="text" name="phone" placeholder="Ex: 999-999-9999" />
                </label>
                </div>
                <div className="form-group form-group-lg">
                <label className="col-12" for="inMessage">
                    Message:
                    <textarea id="inMessage" rows="3" className="form-control input-lg" type="text" name="message" />
                </label>
                </div>
                <div className="form-group">
                <button type="submit" className="btn btn-primary">Send</button>
                </div>
                { serverState.status && (
                  <p className={ !serverState.status.ok ? "errorMsg" : ""
                }>
                  { serverState.status.msg }
                  </p>
                )}

              </form>
            </div>
            
            <div className="col-lg-5">
              <Img fluid={data.gordonparksImage.childImageSharp.fluid} />
            </div>
          </div>
          </div>
        </AnimatedBox>
      </Layout>
    )
  }
  
  export default Contact
  
  export const query = graphql`
  query {
    gordonparksImage: file(sourceInstanceName: { eq: "images" }, name: { eq: "customersupport" }) {
      childImageSharp {
        fluid(quality: 95, maxWidth: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
  `